:root {

    --c-copy: #242424;
    --c-corporate: #8968a6;
    --c-fond: #f0eee7;

    --ff-head: synthese, sans-serif;
    --ff-copy: "PT Serif", sans-serif;

    --fs-copy: 18px;
    @media(min-width: 1440px){
        --fs-copy: 20px;
    }
    --fs-large: #{pxtoem(22)};
    --fs-small: 0.875em;

    --gap: 24px;
    @media(min-width: 1024px){
        --gap: 32px;
    }
    --gap-2x: calc(2 * var(--gap));
    --gap-4x: calc(4 * var(--gap));
    --m-vert: 3em;
    --m-vert-slim: 2em;
    --max-width: 102004px;
    --margin: 1.5rem;
    --radius-small: 0.5em;
    @media (min-width: 768px) {
        --m-vert: #{pxtorem(57)};
        --margin: #{pxtovw(30, 768)};
    }
    @media (min-width: 1024px) {
        --m-vert: #{pxtorem(120)};
        --margin: #{pxtovw(50, 1024)};
        --margin: 120px;
    }
    @media (min-width: 1280px) {
    }
    --margin-wide: 2rem;
    @media (min-width: 768px) {
        --margin-wide: calc(111 / 375 * 100vw);
    }
    @media (min-width: 1024px) {
        --margin-wide: calc(48 / 1024 * 100vw);
    }
    @media (min-width: 1280px) {
        --margin-wide: calc( 50% - 593px );
    }
}
