@import "config.scss";
.jPanelMenu-panel {
    @media screen and (min-width: 850px) {
        display: none;
    }
}

@keyframes kenburns {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.1);
    }
}

header.main-header {
    display: flex;
    position: relative;
    padding: var(--gap-2x) var(--gap);
    @media(min-width: 600px){
        padding: var(--gap-2x) var(--gap-2x) var(--m-vert);
        width: 100%;
    }
    @media print {
        padding: var(--gap-2x) var(--gap);
    }
    font-family: $ff_copy;
    font-weight: 300;
    align-items: flex-start;
    justify-items: flex-start;
    font-size: pxtorem(16);
    .logo {
        display: flex;
        align-items: center;
        text-decoration: none;
        img {
            width: 3.24em;
        }
        &>div {
            margin-left: 0.71em;
            color: var(--c-copy);
            text-decoration: none;
            line-height: 1.2;
            margin-right: var(--gap);
            font-size: 1.24rem;
            @media(min-width: 1280px){
                font-size: pxtoem(22);
            }
            p:first-child {
                font-family: $ff_head;
                font-weight: 700;
                &+p {}
            }
        }
    }
    .nav {
        @media print {
            display: none;
        }
        .socials {
            img {
                width: 2.4em;
            }
            a+a {
                margin-left: var(--gap);
            }
        }
        @media(min-width: 600px){
            .socials {
                display: none;
            }
        }
        @media(max-width: 599px){
            position: fixed;
            z-index: 1986;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            nav {
                margin-bottom: var(--gap-2x);
            }
            opacity: 1;
            transform: translateX(0);
            transition: all 400ms;
            &.closed {
                opacity: 0;
                transition: all 400ms;
                transform: translateX(100%)
            }
        }
    }
    nav {
        font-family: $ff_head;
        font-size: 1.246rem;
        @media(min-width: 600px){
            font-size: 0.875rem;
        }
        @media(min-width: 1280px){
            font-size: 1rem;
        }
        @media(min-width: 1440px){
            font-size: 1em;
        }
        ul {
            display: flex;
            @media(max-width: 599px){
                flex-direction: column;
                li:not(:last-child) {
                    margin-bottom: 1em;
                }
            }
            @media(min-width: 600px){
                &.side {
                    position: absolute;
                    right: 0;
                    top: 0;
                    transform: rotate(-90deg);
                    transform-origin: bottom right ;
                }
                li {
                    margin-left: 2em;
                }
            }
            li {
                a {
                    color: var(--c-copy);
                    text-decoration: none;
                    &.active {
                        color: var(--c-corporate);
                    }
                    &:hover {
                        color: var(--c-corporate);
                    }
                }
            }
            &.main {
                font-weight: 700;
                margin-top: 4px;
            }
        }
        &.side {
            @media(min-width: 600px){
                position: absolute;
                z-index: 2;
                top: calc(50px + var(--m-vert));
                left: calc(var(--gap-2x) + 1em );
            }
        }
    }
    .socials {
        @media print {
            display: none;
        }
        @media(max-width: 413px){
            &.head {
                display: none;
            }
        }
        &.head {
            img {
                width: 1.8em;
                margin-left: 1em;
            }
        }
        span {
            display: flex;
            align-items: center;
            font-family: var(--ff-head);
            height: 100%;
            padding: 0 1em 0.2em;
            text-decoration: none;
            font-weight: bold;
            background: var(--c-copy);
            color: white;
            border-radius: 8px;
            text-transform: uppercase;
        }
        display: flex;
        align-items: stretch;
        margin-left: auto;
    }

    .rwd {
        @media(min-width: 600px) {
            display: none;
        }
        position: absolute;
        z-index: 1989;
        right: var(--gap);
        top: 50%;
        width: 30px;
        height: 18px;
        transform: translateY(-50%);

        span {
            position: absolute;
            left: 50%;
            width: 30px;
            top: 50%;
            transform: translate(-50%,-50%);
            transform-origin: center center;
            height: 1px;
            background: var(--c-copy);
            &.r1 {
                top: 0;
            }
            &.r3 {
                top: auto;
                bottom: 0;
            }
        }

        &:not(.closed) {
            .r2 {
                display: none;
            }
            .r1 {
                top: 50%;
                transform: translate(-50%, -50%) rotate(45deg);
            }
            .r3 {
                bottom: 50%;
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        }

    }


}
