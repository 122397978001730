.imageportfolio {
    position: relative;
    .swiper-slide {
        width: auto;
        img {
            height: 40vh;
            @media(min-width: 1280px){
                height: 480px;
            }
            max-height: 480px;
            width: auto;
        }
    }
    &.websites {
        .swiper-slide {
            img {
                height: auto;
                width: 40vw;
            }
            @media(min-width: 1280px) {
                height: auto;
                width: 560px;
            }
            max-width: 560px;
            max-height: auto;
        }
    }
    a {
        display: flex;
        transition: transform 100ms;
        img {
            @extend .shadow;
        }
        &:hover imsg {
            transform: scale(1.01);
            transition: transform 0.8s;
        }
        &:hover {
            transform: scale(1.024);
            transition: transform 200ms;
        }
    }
    .portfolio-nav {
        span {
            cursor: pointer;
            position: absolute;
            z-index: 1986;
            left: 8px;
            top: 50%;
            transform: translateY(-50%);
            display: block;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 38px;
            height: 38px;
            background: white;
            border-radius: 19px;
            &.portfolio-next {
                left: auto;
                right: 8px;
                padding-left: 2px;
            }
            svg path {
                    stroke: var(--c-copy);
            }
            &:hover svg path {
                stroke: $c_secondary;
            }
        }
    }
}