
article.book {
    .head {
        .shelf {
            @media print {
                display: none;
            }
        }
        margin-bottom: var(--gap-2x);
        @media(min-width: 1024px) {
            padding-left: calc(24% + var(--gap-2x));
            p {
                flex-shrink: 0;
                display: flex;
                align-items: center;
            }
        }
        p {
            a {
                font-family: var(--ff-head);
                opacity: 0.5;
                color: var(--c-copy);                }   
            }

        }
        h1 {
            margin: 0 !important;
            span:first-child {
                font-size: 0.5em;
                font-weight: normal;
            }
    }
    @media(max-width: 767px){
        .image {
            span {
                display: block;
            }
            display: flex;
            justify-content: center;
            img {
                width: 100%;
                @media screen {
                    max-width: 240px;
                }
            }
        }
    }
    .inner {
        .details {
            margin-bottom: 1em;
            font-size: 16px;
            text-align: center;
            p {
                font-family: var(--ff-head);
                margin: 0 0 0.5em;
            }
            .button {
                background-color: var(--c-copy);
                display: block;
            }
            .buy_on {
                @media print {
                    display: none;
                }
                &:nth-of-type(1) {
                    margin-top: var(--gap);
                }
            }
        }
        @media(min-width: 1024px), print {
            display: flex;
            .details {
                flex-shrink: 0;
                width: 24%;
                margin: 0;
            }
            .text-wrap {
                margin-left: var(--gap-2x);
            }
        }
    }
    .read_too {
        margin: var(--gap-2x) 0;
    }
}

.bookshelf {
    width: 100%;
    display: grid;
    grid-gap: var(--gap-2x);
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--gap);
    @media(min-width: 600px){
    }
    @media(min-width: 768px){
        grid-template-columns: repeat(3, 1fr);
    }
    @media(min-width: 1024px){
        grid-template-columns: repeat(4, 1fr);
    }
    .book {
        img {
            width: 100%;
        }
        display: flex;
        flex-direction: column;
        .image {
            display: flex;
            justify-content: center;
            a {
                display: flex;
                justify-content: center;
                align-items: center;
                @media(max-width: 767px){
                    max-width: 246px;
                }
            }
            img {
                width: 86%;
            }
            width: 100%;
            padding-bottom: 140%;
            position: relative;
            a {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                img {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                        width: auto;
                        height: 100%;
                    object-fit: contain;
                    object-position: center;
                    transition: transform 0.3s;
                }
                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    bottom: 1em;
                    right: -0.5em;
                    z-index: 1986;
                    width: 4.5em;
                    border-radius: 4em;
                    padding: 1em;
                    background: var(--c-copy);
                    color: white;
                    border: solid 3px white;
                    transform: scale(1) rotate(-6deg);
                    transition: transform 0.5s;
                }
                &:hover {
                    img {
                        transform: translateX(-50%) scale(1.05);
                    }
                    span {
                        background: var(--c-corporate);
                        transform: scale(1.1) rotate(6deg);
                    }
                }
            }
        }
        .details {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-top: var(--gap);
            font-family: var(--ff-head);
            .title {
                min-height: 5.5em;
                @media(min-width: 768px){
                    min-height: 5em;
                }
                h3, p {
                    margin: 0;
                }
                h3 {
                    font-size: var(--fs-copy);
                    font-weight: bold;
                }
                p {
                    font-size: var(--fs-small);
                }
            }
            .buy_on {
                text-align: center;
                margin: 1em 0;
                &.two-buttons {
                    display: grid;
                    grid-gap: 2px;
                    grid-template-columns: repeat(2, 1fr);
                }
                @media(max-width: 767px){
                    .lang {
                        display: none;
                    }
                }
            }
            .more {
                font-size: var(--fs-small);
                text-decoration: none;
                color: var(--c-copy);
            }
        }
    }
}

article.blog:has(.text>.outer>.bookshelf) {
    .outer {
        background: var(--c-fond);
        padding: var(--gap);
        margin-top: var(--gap-2x);
        margin-bottom: var(--gap-2x);
    }
    .bookshelf {
        gap: 1em;
        @media(min-width: 1024px){
            grid-template-columns: repeat(3, 1fr);
            h3,
            .buy_on {
                font-size: var(--fs-small);
            }
    }
    }
}

img.bookcover {
    margin: 0;
    width: calc(100% - 6px);
    box-shadow: 1px 1px 0 white,2px 2px 0 #868686,3px 3px 0 white,4px 4px 0 #868686,5px 5px 0 white, 6px 6px 0 #868686;
}
