.boxes {
    display: grid;
    gap: var(--gap);
    @media(min-width: 600px){
        @for $i from 1 through 10 {
            &.boxes-#{$i} {
                $fr: '';
                @for $j from 1 through $i {
                    $fr: $fr + ' ' + '1fr';
                }
                grid-template-columns: unquote($fr);
            }
        }
    }
    &.mod-2-0 {
        @media(min-width: 768px) and (max-width: 1279px) {
            grsid-template-columns: 1fr 1fr;
        }

    }
    .box {
        background-color: var(--c-copy);
        background-size: cover;
        background-repeat: no-repeat;
        color: white;
        .inner {
            padding: var(--gap);
            h3 {
                @extend .h4;
            }
            &>p {
                margin-top: var(--gap);

                a {
                    font-family: $ff_head;
                    @extend .h4;
                    text-decoration: none;
                    color: white;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        .image + .inner {
            padding-top: 0;
        }
    }
}