#soulstrips {
    margin: var(--gap-2x);
    display: grid;
    grid-template-columns: repeat(2,1fr);
   @media(min-width: 600px){
        grid-template-columns: repeat(3,1fr);
    }
     @media(min-width: 1024px){
        grid-template-columns: repeat(5,1fr);
    }
    gap: var(--gap-2x);
    a {
        display: block;
        img {
            position: relative;
        }
        div {
            background: rgba(0,0,0,0.075);
            mix-blend-mode: multiply;
        }
    }
}