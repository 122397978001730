@import "config.scss";
footer {
    @media print {
        display: none;
    }
    padding: 2em;
    border: solid var(--gap) white;
    background: $c_fond;
    display: flex;
    justify-content: center;
    .wrap {
        max-width: 720px;
    }
    a {
        color: #087da8 !important;
        text-decoration: none;
    }
    .ty h3 {
        margin: 1em 0;
    }
    .alternative {
        display: flex;
        justify-content: center;
        a {
            @media(min-width: 600px){
                margin: 0 1em;
            }
            color: var(--c-copy) !important;
            font-size: var(--fs-small);
            span {
                display: block;
            }
        }
    }
    nav {
        ul {
            display: flex;
            justify-content: center;
            li {
                margin: 0 1em;
            }
        }
    }
}
section.legal {
    @media print {
        display: none;
    }
    background: white;
    display: flex;
    justify-content: center;
    padding: 0 var(--gap) var(--gap);
    font-size: var(--fs-small);
    a {
        color: var(--c-copy);
        text-decoration: none;
    }
}
.london {
        @media print {
        display: none;
    }
   padding: 1em 0;
    text-align: center;
    background: var(--c-copy);
    color: white;
    font-size: 1.55em;
    line-height: 1;
    font-family: $ff_head;
    font-weight: bold;
    text-transform: uppercase;
    background: linear-gradient(90deg,rgba(255, 0, 0, 1) 0%, rgba(255, 154, 0, 1) 10%, rgba(208, 222, 33, 1) 20%, rgba(79, 220, 74, 1) 30%, rgba(63, 218, 216, 1) 40%, rgba(47, 201, 226, 1) 50%, rgba(28, 127, 238, 1) 60%, rgba(95, 21, 242, 1) 70%, rgba(186, 12, 248, 1) 80%, rgba(251, 7, 217, 1) 90%, rgba(255, 0, 0, 1) 100%) 0 0/200% 100%;
    animation: a 24s linear infinite;
}

@keyframes a {
    to {
        background-position: -200% 0
    }
}

