.taxonomies.swiper-container {
    .taxonomy {
        width: auto;
        padding: 0.3em 1em;
        background: var(--c-copy);
        color: white;
        &.active {
            background-color: $c_corporate;;
        }
    }
}