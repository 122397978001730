.feature {
    &>div {
        @media(min-width: 1024px){
            grid-template-columns: 1fr 1fr;
        }
        display: grid;
        gap: var(--gap);
    }
    &>.h1,
    .text {
        @media(min-width: 1024px){
            padding-left: var(--gap);
        }
        @media(min-width: 1280px){
            padding-left: var(--gap-2x);
        }
        p:first-child strong {
            @extend .abstract;
        }
    }
}