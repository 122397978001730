$c_head: #242424;
$c_corporate: #8968a6;
$c_link: $c_corporate;
$c_secondary: #9538DF;
$c_button: $c_secondary;
$c_rot: #c5403d;
$c_red: $c_rot;
$c_green: #52a049;
$c_gruen: $c_green;
$c_blue: #4063c6;
$c_blau: $c_blue;
$c_fond: #f0eee7;

//

$ff_copy: "PT Serif", sans-serif;
$ff_head: synthese, sans-serif;
$ff_fixed: monospace;
//
$ff_maxwidth: 1024px;
$ff_maxwidth_wide: $ff_maxwidth;
