@import "fonts";
@import "variables";

/* Font-Groessen */

$fs_fixed: var(--fs-copy);
$fs_table: var(--fs-copy);
$fs_small: 14px;

/* Dimensionen */

@import "functions";