.ty,
.typography {
    b,
    strong {
        font-weight: bold;
    }
    i,
    em {
        font-style: italic;
    }
    ul {
        display: inline;
    }
    blockquote,
    pre {
        padding: 1rem 0;
    }
    .wp-block-quote,
    blockquote {
        border-top: solid 1.5px var(--c-copy);
        border-bottom: solid 1.5px var(--c-copy);
        margin-top: var(--gap-2x);
        margin-bottom: var(--gap-2x);
        padding: var(--gap) 0 var(--gap) 3em;
        background: url("../images/Quote.svg") no-repeat 0 calc(var(--gap) + 0.5em);
        font-family: var(--ff-head);
        p {
            font-weight: 300;
            &:last-child {
                margin-bottom: 0;
            }
            &:last-child:not(:only-child) {
                em, i {
                    font-family: var(--ff-copy);
                }
            }
        }
    }
    pre {
        font-family: $ff_fixed;
        line-height: 1.5;
        background: black;
        color: white;
    }
    color: var(--c-copy);
    dd,
    dt,
    ul,
    ol,
    p {
        font-weight: 400;
        margin: 0 0 1em 0;
        line-height: 1.7;
        font-size: 1em;
    }
    .small {
        font-size: var(--fs-small);
    }
    .large {
        font-size: var(--fs-large);
    }
    p:last-child {
        margin-bottom: 0;
    }
    ul {
        li:not([class]) {
            margin-bottom: 1em;
            padding-left: 1.6em;
            margin-left: 0;
            line-height: 1.7;
            background: url("../images/Bullet.svg") no-repeat 0 0.7em;
        }
        ul {
            margin-left: 1em;
        }
        &:last-child {
            li:last-child {
                margin-bottom: 0;
            }
        }
    }
    ul li {
        list-style-type: none;
    }
    ol li {
        list-style-type: decimal;
        margin-left: 1em;
        margin-bottom: 1em;
    }
    ul,
    ol {
        margin: 1em 0;
        line-height: 1.4;
    }
    caption,
    .h1,
    .h2,
    h1,
    h2,
    h3,
    h4,
    h5 {
        margin-top: 0.86em;
        margin-bottom: 1.24em;
        line-height: 1.2;
        font-family: $ff_head;
        font-weight: normal;
        a {
            text-decoration: none;
            color: var(--c-copy);
            &:hover {
                color: black;
            }
        }
        &:first-child {
            margin-top: 0;
        }
    }
    .h1 {
        font-family: $ff_head;
        font-size: clamp(18px,pxtovw(28),48px);
        @media(min-width: 1280px){
            font-size: 3em;
        }
        font-weight: 900;
        &.huge {
            @media(min-width: 1280px){
                font-size: pxtorem(86);
            }
            font-weight: 900;
        }
        @media print {
            font-size: 24pt;
        }
    }
    h1 {
        @extend .h1;
    }
    .h2,
    h2 {
        font-size: pxtoem(28);
        font-weight: 600;
        margin-top: 2em;
        @media print {
            font-size: 16pt;
        }
    }
    .h3,
    caption,
    h3 {
        margin-top: 2em;
        font-size: pxtoem(20);
        @media(min-width: 1280px){
            font-size: pxtoem(24);
        }
        @media print {
            font-size: 14pt;
        }
    }
    .h4,
    h4,
    h5 {
        font-size: 1.2em;
        font-weight: bold;
    }
    h1 + *,
    h2 + *,
    h3 + *,
    .coltop + h1,
    .coltop + h2,
    .coltop + h3,
    .coltop + h4,
    .coltop + h5,
    a[name="copytop"] + h1,
    a[name="copytop"] + h2,
    a[name="copytop"] + h3,
    a[name="copytop"] + h4,
    a[name="copytop"] + h5 {
        margin-top: 0;
    }
    a {
        color: $c_link;
        &:hover {
            color: $c_head;
            &:active {
                color: $c_link;
            }
        }
    }
    .wp-caption p {
        font-size: 0.8em;
        margin: 0.5em 0 1em 0;
    }
}
