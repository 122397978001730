.green-audio-player {
    width: auto;
    margin-top: var(--m-vert-slim);
    margin-bottom: var(--m-vert-slim);
.controls .controls__slider {
    margin-right: 0;
    width: auto;
}
.pin.progress__pin {
    background-color: $c_corporate !important;
}

}