@import "config.scss";
html {
    zoom: 100%;
    * {
        box-sizing: border-box;
    }
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    font-family: $ff_copy;
    font-size: var(--fs-copy);
    @media print {
        font-size: 11pt;
        background: white !important
    }
    -webkit-font-smoothing: antialiased;
    color: var(--c-copy);
    background: #f0eee7;
    scroll-behavior: smooth;
}

.wrap {
    padding: 0 20px;
    @media (min-width: 375px) {
        padding: 0 30px;
    }
    @media (min-width: 1024px) {
        max-width: pxtovw(970,1280);
        padding: 0;
        &.wide {
            max-width: 1000px;
        }
    }
    @media (min-width: 1280px) {
        max-width: 1000px;
        &.section {
            max-width: 1186px;
        }
        &.wide {
            max-width: 1186px;
        }
    }
    margin: 0 auto;
    position: relative;
}

#wrapall {
    margin: 0 auto;
    max-width: 1440px;
    overflow: hidden;
    background: white;
    @extend .shadow;
    @media print {
        box-shadow: none;
    }
    @media (min-width: 1442px) {
        margin: var(--gap) auto;
        border-radius: var(--gap);
    }
}
