.podcastfeeds {
    display: grid;
    @media(min-width: 600px){
        grid-template-columns: repeat(2, 1fr);
    }
    grid-gap: 1rem;
    margin: var(--m-vert-slim) 0;
    a {
        display: flex;
        align-items: flex-start;
        color: white;
        padding: 1rem;
        border-radius: 1.5rem;
        text-decoration: none;
        background: #242424;
        color: white !important;
        &:hover {
            transform: scale(1.024);
            color: white;
        }
        span {
            margin-left: 1rem;
        }
        h3 {
            margin: 0;
            font-size: 1.2rem;
        }
        p {
            margin: 0;
            font-size: 0.8rem;
        }
    }
}