.more-blog-posts {
    @media print {
        display: none;
    }
    margin-top: var(--m-vert);

    h2 {
        padding-bottom: var(--gap-2x);
    }
}

.blog-and-pictures {
    position: relative;
    z-index: 2;
    background: #50435B;
}

article.blog {
    .head {
        width: 100%;
        @media (screen) {
            padding-left: var(--margin-wide);
        }
        padding-right: var(--margin-wide);
    }
    .inner {
        .date {
            margin-bottom: 1em;
        }

        @media(min-width: 1024px) {
            display: flex;

            .date {
                flex-shrink: 0;
                width: var(--margin);
                margin: 0;
            }

            .text {
                margin-left: var(--gap);
            }
        }
    }
}

.pagination {
    display: flex;
    justify-content: center;
    font-family: var(--ff-head);
    span,
    a {
        display: block;
        color: var(--ff-copy);
        padding: 1em;
        text-decoration: none;
        font-size: var(--fs-large)
    }
    span {
        opacity: 0.24;
    }
    margin-top: var(--m-vert-slim);
    margin-bottom: var(--m-vert-slim);
}

.grid {
    opacity: 0;
    max-width: var(--max-width);
    margin: 0 var(--gap);
    &.show {
        opacity: 1;
    }
}

.grid-sizer-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    @media(min-width: 1204px){
        grid-template-columns: repeat(3, 1fr);
    }
    @media(min-width: 1280px){
        grid-template-columns: repeat(4, 1fr);
    }
}

.grid-sizer:not(.ignore) {
    display: block;
    width: 100%;

    @media (min-width: 60px) {
        width: calc(50% - var(--gap) / 2);
    }

    @media (min-width: 1024px) {
        --gap2: calc(2 * var(--gap));
        width: calc(33.333% - var(--gap2) / 3);
    }

    @media (min-width: 1280px) {
        --gap3: calc(3 * var(--gap));
        width: calc(25% - var(--gap3) / 4);
    }
}

.grid-gutter {
    display: block;
    width: var(--gap);
}

.grid {

    &:not(.no-offset) {
        @media(min-width: 1024px) {
            transform: translateY(calc(-3 * var(--gap)));
        }
    }

    padding: var(--gap) 0;
}

.grid-item {
    text-decoration: none;
    margin-bottom: 20px;
    width: 100%;
    overflow: hidden;

    &.tile {
        @extend .shadow;
    }

    &:not(.picture) {
        border-radius: 4px;
    }

    &.picture {
        transform: scale(0.94);
    }

    img {
        display: flex;
    }

    div:first-child {
        display: flex;

        img {
            display: flex;
            width: 100%;
        }
    }

    .hover {
        opacity: 0;
    }

    .inner {
        @media(min-width: 600px) {
            margin: 1em;
        }

        @extend .shadow;
        border-radius: 4px;
    }

    .inner,
    .bottom {
        padding: 1em;

        @media(min-width: 600px) {
            padding: var(--gap);
        }

        background: $c_corporate;
        color: white;

        h3 {
            font-size: 12px;

            @media(min-width: 600px) {
                font-size: 1em;
            }

            font-weight: 700;
            text-transform: none;
            margin: 0;
        }

        &>p {
            margin: 1em 0;
            font-size: 0.75em;

            &:last-child {
                @media(max-width: 599px) {
                    display: none;
                }

                margin-bottom: 0;
                opacity: 0.5;
            }
        }

        .excerpt {
            font-size: pxtoem(12);
            background: rgba(black, 0.86);
            padding: var(--gap);
            display: none;
        }
    }

    &:hover {
        @media(min-width: 1024px) {
            .hover {
                opacity: 1;
            }

            .bottom .excerpt {
                display: flex;
            }
        }
    }
}
