.f-header,
.f-icons {
    display: flex;
}

.f-icons-inner {
    display: grid;
    grid-gap: var(--gap);
}

.f-header {
    & > div {
        width: 50%;
        flex-shrink: 0;
        & + div {
            padding: 0 var(--gap);
        }
    }
}

.f-spalten,
.columns,
.f-columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--gap);
}
