#mc_embed_signup .button,
a.button,
.button {
    -webkit-appearance: none;
    appearance: none;
    display: inline-block;
    padding: 0.5em 1em 0.7em;
    background: $c_button;
    color: white !important;
    font-family: $ff_head;
    text-decoration: none;
    font-size: 1em;
    font-weight: bold;
    border: none;
    text-align: center;
    &.block {
        display: block;
        width: 100%;
    }
    &.small {
        font-size: var(--fs-small);
    }
    &.black {
        background:var(--c-copy);
        &:hover {
            background: var(--c-corporate);
        }
    }
    &:hover {
        color: white;
        background: darken($c_button, 20%);
        &:active {
            background: darken($c_button, 20%);
        }
    }
}



.mb {
    &:not(:first-child) {
        margin-top: var(--m-vert);
    }
    margin-bottom: var(--m-vert);
}

.text+.mb {
    margin-top: 0;
}

div.taxonomy.swiper-container {
    .swiper-slide { 
        width: auto;
    }
    .swiper-slide span {
        width: auto;
        display: flex;
        justify-content: center;
        margin: 0 0 0 var(--gap);
        padding: 0.3em 1em 0.5em;
        line-height: 1;
        border: solid 2px var(--c-copy);
        font-family: $ff_head;
        border-radius: var(--radius-small);
        list-style: none;
        cursor: pointer;
        white-space: nowrap;
        &:first-child {
            margin-left: 0;
        }
        &:hover {
            background: var(--c-copy);
            color: white;
        }
    }
}

img.lazy {
    opacity: 0;
}

