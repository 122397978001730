article > .content:not(:first-child) {
    padding: var(--m-vert) 0;
}
.content {
    @extend .typography;
    ._brlbs-switch-consent,
    & > .text,
    & > .taxonomies,
    & > .podcastfeeds,
    & > .columns,
    & > .newsletterform,
    & > .abstract,
    & > .feature,
    & > .wrapThis,
    & > form,
    & > ul,
    & > ol,
    & > blockquote,
    & > p,
    & > dd,
    & > h1,
    & > h2,
    & > h3,
    & > h4,
    & > h5,
    & > h6,
    & > .f-text,
    & > .f-header,
    & > .f-icons,
    & > .f-spalten,
    & > .f-flow,
    & > .f-columns,
    & > .f-columns_linked,
    & > .f-testff,
    & > .infobox,
    & > .boxes,
    & > figure {
        @media (max-width: 1023px) {
            padding-left: var(--gap);
            padding-right: var(--gap);
        }
        @media(min-width: 1024px){
            margin-left: var(--margin);
            margin-right: var(--margin);
        }
    }
    & >form,
    & > .podcastfeeds,
    & > .newsletterform,
    & > .abstract,
    & > .text {
        padding-left: var(--gap);
        margin-bottom: var(--m-vert-slim);
        @media(min-width: 1280px) {
            padding-left: calc(var(--m-vert) + var(--gap-2x));
        }
    }
    & > ul {
        margin-top: 2em;
        margin-bottom: 2em;
    }
    h2 + ul {
        margin-top: 1em;
    }
    p:first-child:empty {
        display: none;
    }
    & > .group {
        margin-left: auto;
        margin-right: auto;
    }
    & > .group {
        margin-bottom: 1em;
    }
    figure {
        margin: var(--m-vert-slim) 0;
    }
    figcaption {
        margin-top: 1em;
        font-size: var(--fs-small);
    }
    li {
        background: none;
        padding-left: 0;
    }
    .columns {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
    .video {
        @media print {
            display: none;
        }
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.1),
	0 10px 6px -6px rgba(0, 0, 0, 0.3);
        position: relative;
        width: 100%;
        padding-bottom: 56%;
        background: #efefef;
        margin-top: var(--m-vert-slim);
        margin-bottom: var(--m-vert-slim);
        iframe {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }
    @import "colors.scss";
}

        .content>img:only-child {
            margin-top: calc(var(--gap-2x) - 1rem);
            margin-bottom: var(--gap-2x);
        }
