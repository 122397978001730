.frontpage {
    .wrap-front {
        position: relative;
        display: flex;
        align-items: flex-start;
        padding-left: var(--gap);
        @media(min-width: 600px){
            padding-left: var(--gap-2x);
        }
        @media(min-width: 1024px){
            padding-left: 200px;
        }
        .markus {
            display: flex;
            position: relative;
            z-index: 1;
            flex-shrink: 0;
            width: 50%;
        }
        .rainbow {
            position: absolute;
            right: 0;
            bottom: 0;
            width: calc(50% - 22vw);
            @media(min-width: 600px){
                width: calc(50% - var(--gap-2x));
            }
            height: pxtovw(400,375);
            @media(min-width: 600px){
                height: 539px;
                width: calc(50% - var(--gap-4x));
            }
            @media(min-width: 1024px){
                width: calc(50% - 200px);
            }
            background: linear-gradient(
                180deg,
                #da4b4b 0%,
                #e9ef56 20%,
                #56bf4a 40%,
                #49c7bf 60%,
                #5350c7 80%,
                #d65fc3 100%
            );
        }
        .copy {
            flex-grow: 1;
            @media(min-width: 600px){
                font-size: pxtorem(22);
            }
            @media(min-width: 1024px){
                font-size: 1em
            }
            @media(min-width: 1440px){
                font-size: pxtorem(22);
            }
            & > p:first-child {
                font-size: pxtoem(48);
                @media(min-width: 1024px){
                    font-size: pxtoem(62);
                }
                font-family: $ff_head;
                font-weight: 700;
                margin: 0;
                & + p {
                    margin: 0 0 1em;
                    font-weight: 300;
                }
            }
                h4 {
                    font-size: pxtoem(22);
                }
        }
    }
    & + section {
        position: relative;
        z-index: 2;
        background: #50435B;
    }
}
