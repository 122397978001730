.col-art {
    background: $c_red;
}

.col-code {
    background: $c_green;
}

.col-mentoring {
    background: $c_blue;
}