.offerings {
    .wrap>div {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: var(--gap);
        a {
            @extend .shadow;
            text-decoration: none;
            padding: var(--gap);
            background: $c_rot;
            color: white;
            .h2,
            h2 {
                font-size: pxtoem(24);
            }
        }
        h4 {
            margin-top: 3em;
        }
    }
}