@charset "UTF-8";
@import url("https://use.typekit.net/fiu0nlq.css");
@import url("https://use.typekit.net/fiu0nlq.css");
@import url("https://use.typekit.net/fiu0nlq.css");
@import url("https://use.typekit.net/fiu0nlq.css");
@import url("https://use.typekit.net/fiu0nlq.css");
@import url("https://use.typekit.net/fiu0nlq.css");
@import url("https://use.typekit.net/fiu0nlq.css");
@import url("https://use.typekit.net/fiu0nlq.css");
@font-face {
  font-family: "PT Serif";
  font-weight: normal;
  src: url(../fonts/PTSerif-Regular.ttf) format("truetype");
}
/* Font-Groessen */
/* Dimensionen */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td, input, select {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

/* remember to define focus styles! */
:focus {
  outline: 0;
}

body {
  line-height: 1;
  color: black;
  background: white;
}

ol, ul {
  list-style: none;
}

/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: separate;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
}

blockquote, q {
  quotes: "" "";
}

:root {
  --c-copy: #242424;
  --c-corporate: #8968a6;
  --c-fond: #f0eee7;
  --ff-head: synthese, sans-serif;
  --ff-copy: "PT Serif", sans-serif;
  --fs-copy: 18px;
  --fs-large: 1.375em;
  --fs-small: 0.875em;
  --gap: 24px;
  --gap-2x: calc(2 * var(--gap));
  --gap-4x: calc(4 * var(--gap));
  --m-vert: 3em;
  --m-vert-slim: 2em;
  --max-width: 102004px;
  --margin: 1.5rem;
  --radius-small: 0.5em;
  --margin-wide: 2rem;
}
@media (min-width: 1440px) {
  :root {
    --fs-copy: 20px;
  }
}
@media (min-width: 1024px) {
  :root {
    --gap: 32px;
  }
}
@media (min-width: 768px) {
  :root {
    --m-vert: 3.5625rem;
    --margin: 3.90625vw;
  }
}
@media (min-width: 1024px) {
  :root {
    --m-vert: 7.5rem;
    --margin: 4.8828125vw;
    --margin: 120px;
  }
}
@media (min-width: 768px) {
  :root {
    --margin-wide: calc(111 / 375 * 100vw);
  }
}
@media (min-width: 1024px) {
  :root {
    --margin-wide: calc(48 / 1024 * 100vw);
  }
}
@media (min-width: 1280px) {
  :root {
    --margin-wide: calc( 50% - 593px );
  }
}

.shadow, .grid-item .inner, .grid-item.tile, .imageportfolio a img, .offerings .wrap > div a, #wrapall {
  box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.25);
}

.shadow-light {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
}

.ty,
.typography,
article,
.content {
  color: var(--c-copy);
}
.ty b,
.ty strong,
.typography b,
article b,
.content b,
.typography strong,
article strong,
.content strong {
  font-weight: bold;
}
.ty i,
.ty em,
.typography i,
article i,
.content i,
.typography em,
article em,
.content em {
  font-style: italic;
}
.ty ul,
.typography ul,
article ul,
.content ul {
  display: inline;
}
.ty blockquote,
.ty pre,
.typography blockquote,
article blockquote,
.content blockquote,
.typography pre,
article pre,
.content pre {
  padding: 1rem 0;
}
.ty .wp-block-quote,
.ty blockquote,
.typography .wp-block-quote,
article .wp-block-quote,
.content .wp-block-quote,
.typography blockquote,
article blockquote,
.content blockquote {
  border-top: solid 1.5px var(--c-copy);
  border-bottom: solid 1.5px var(--c-copy);
  margin-top: var(--gap-2x);
  margin-bottom: var(--gap-2x);
  padding: var(--gap) 0 var(--gap) 3em;
  background: url("../images/Quote.svg") no-repeat 0 calc(var(--gap) + 0.5em);
  font-family: var(--ff-head);
}
.ty .wp-block-quote p,
.ty blockquote p,
.typography .wp-block-quote p,
article .wp-block-quote p,
.content .wp-block-quote p,
.typography blockquote p,
article blockquote p,
.content blockquote p {
  font-weight: 300;
}
.ty .wp-block-quote p:last-child,
.ty blockquote p:last-child,
.typography .wp-block-quote p:last-child,
article .wp-block-quote p:last-child,
.content .wp-block-quote p:last-child,
.typography blockquote p:last-child,
article blockquote p:last-child,
.content blockquote p:last-child {
  margin-bottom: 0;
}
.ty .wp-block-quote p:last-child:not(:only-child) em, .ty .wp-block-quote p:last-child:not(:only-child) i,
.ty blockquote p:last-child:not(:only-child) em,
.ty blockquote p:last-child:not(:only-child) i,
.typography .wp-block-quote p:last-child:not(:only-child) em,
article .wp-block-quote p:last-child:not(:only-child) em,
.content .wp-block-quote p:last-child:not(:only-child) em,
.typography .wp-block-quote p:last-child:not(:only-child) i,
article .wp-block-quote p:last-child:not(:only-child) i,
.content .wp-block-quote p:last-child:not(:only-child) i,
.typography blockquote p:last-child:not(:only-child) em,
article blockquote p:last-child:not(:only-child) em,
.content blockquote p:last-child:not(:only-child) em,
.typography blockquote p:last-child:not(:only-child) i,
article blockquote p:last-child:not(:only-child) i,
.content blockquote p:last-child:not(:only-child) i {
  font-family: var(--ff-copy);
}
.ty pre,
.typography pre,
article pre,
.content pre {
  font-family: monospace;
  line-height: 1.5;
  background: black;
  color: white;
}
.ty dd,
.ty dt,
.ty ul,
.ty ol,
.ty p,
.typography dd,
article dd,
.content dd,
.typography dt,
article dt,
.content dt,
.typography ul,
article ul,
.content ul,
.typography ol,
article ol,
.content ol,
.typography p,
article p,
.content p {
  font-weight: 400;
  margin: 0 0 1em 0;
  line-height: 1.7;
  font-size: 1em;
}
.ty .small,
.typography .small,
article .small,
.content .small {
  font-size: var(--fs-small);
}
.ty .large,
.typography .large,
article .large,
.content .large {
  font-size: var(--fs-large);
}
.ty p:last-child,
.typography p:last-child,
article p:last-child,
.content p:last-child {
  margin-bottom: 0;
}
.ty ul li:not([class]),
.typography ul li:not([class]),
article ul li:not([class]),
.content ul li:not([class]) {
  margin-bottom: 1em;
  padding-left: 1.6em;
  margin-left: 0;
  line-height: 1.7;
  background: url("../images/Bullet.svg") no-repeat 0 0.7em;
}
.ty ul ul,
.typography ul ul,
article ul ul,
.content ul ul {
  margin-left: 1em;
}
.ty ul:last-child li:last-child,
.typography ul:last-child li:last-child,
article ul:last-child li:last-child,
.content ul:last-child li:last-child {
  margin-bottom: 0;
}
.ty ul li,
.typography ul li,
article ul li,
.content ul li {
  list-style-type: none;
}
.ty ol li,
.typography ol li,
article ol li,
.content ol li {
  list-style-type: decimal;
  margin-left: 1em;
  margin-bottom: 1em;
}
.ty ul,
.ty ol,
.typography ul,
article ul,
.content ul,
.typography ol,
article ol,
.content ol {
  margin: 1em 0;
  line-height: 1.4;
}
.ty caption,
.ty .h1,
.ty .h2,
.ty h1,
.ty h2,
.ty h3,
.ty h4,
.ty h5,
.typography caption,
article caption,
.content caption,
.typography .h1,
article .h1,
.content .h1,
.typography .h2,
article .h2,
.content .h2,
.typography h1,
article h1,
.content h1,
.typography h2,
article h2,
.content h2,
.typography h3,
article h3,
.content h3,
.typography h4,
article h4,
.content h4,
.typography h5,
article h5,
.content h5 {
  margin-top: 0.86em;
  margin-bottom: 1.24em;
  line-height: 1.2;
  font-family: synthese, sans-serif;
  font-weight: normal;
}
.ty caption a,
.ty .h1 a,
.ty .h2 a,
.ty h1 a,
.ty h2 a,
.ty h3 a,
.ty h4 a,
.ty h5 a,
.typography caption a,
article caption a,
.content caption a,
.typography .h1 a,
article .h1 a,
.content .h1 a,
.typography .h2 a,
article .h2 a,
.content .h2 a,
.typography h1 a,
article h1 a,
.content h1 a,
.typography h2 a,
article h2 a,
.content h2 a,
.typography h3 a,
article h3 a,
.content h3 a,
.typography h4 a,
article h4 a,
.content h4 a,
.typography h5 a,
article h5 a,
.content h5 a {
  text-decoration: none;
  color: var(--c-copy);
}
.ty caption a:hover,
.ty .h1 a:hover,
.ty .h2 a:hover,
.ty h1 a:hover,
.ty h2 a:hover,
.ty h3 a:hover,
.ty h4 a:hover,
.ty h5 a:hover,
.typography caption a:hover,
article caption a:hover,
.content caption a:hover,
.typography .h1 a:hover,
article .h1 a:hover,
.content .h1 a:hover,
.typography .h2 a:hover,
article .h2 a:hover,
.content .h2 a:hover,
.typography h1 a:hover,
article h1 a:hover,
.content h1 a:hover,
.typography h2 a:hover,
article h2 a:hover,
.content h2 a:hover,
.typography h3 a:hover,
article h3 a:hover,
.content h3 a:hover,
.typography h4 a:hover,
article h4 a:hover,
.content h4 a:hover,
.typography h5 a:hover,
article h5 a:hover,
.content h5 a:hover {
  color: black;
}
.ty caption:first-child,
.ty .h1:first-child,
.ty .h2:first-child,
.ty h1:first-child,
.ty h2:first-child,
.ty h3:first-child,
.ty h4:first-child,
.ty h5:first-child,
.typography caption:first-child,
article caption:first-child,
.content caption:first-child,
.typography .h1:first-child,
article .h1:first-child,
.content .h1:first-child,
.typography .h2:first-child,
article .h2:first-child,
.content .h2:first-child,
.typography h1:first-child,
article h1:first-child,
.content h1:first-child,
.typography h2:first-child,
article h2:first-child,
.content h2:first-child,
.typography h3:first-child,
article h3:first-child,
.content h3:first-child,
.typography h4:first-child,
article h4:first-child,
.content h4:first-child,
.typography h5:first-child,
article h5:first-child,
.content h5:first-child {
  margin-top: 0;
}
.ty .h1, .ty h1,
.typography .h1,
article .h1,
.content .h1,
.typography h1,
article h1,
.content h1 {
  font-family: synthese, sans-serif;
  font-size: clamp(18px, 7.4666666667vw, 48px);
  font-weight: 900;
}
@media (min-width: 1280px) {
  .ty .h1, .ty h1,
.typography .h1,
article .h1,
.content .h1,
.typography h1,
article h1,
.content h1 {
    font-size: 3em;
  }
}
.ty .h1.huge, .ty h1.huge,
.typography .h1.huge,
article .h1.huge,
.content .h1.huge,
.typography h1.huge,
article h1.huge,
.content h1.huge {
  font-weight: 900;
}
@media (min-width: 1280px) {
  .ty .h1.huge, .ty h1.huge,
.typography .h1.huge,
article .h1.huge,
.content .h1.huge,
.typography h1.huge,
article h1.huge,
.content h1.huge {
    font-size: 5.375rem;
  }
}
@media print {
  .ty .h1, .ty h1,
.typography .h1,
article .h1,
.content .h1,
.typography h1,
article h1,
.content h1 {
    font-size: 24pt;
  }
}
.ty .h2,
.ty h2,
.typography .h2,
article .h2,
.content .h2,
.typography h2,
article h2,
.content h2 {
  font-size: 1.75em;
  font-weight: 600;
  margin-top: 2em;
}
@media print {
  .ty .h2,
.ty h2,
.typography .h2,
article .h2,
.content .h2,
.typography h2,
article h2,
.content h2 {
    font-size: 16pt;
  }
}
.ty .h3,
.ty caption,
.ty h3,
.typography .h3,
article .h3,
.content .h3,
.typography caption,
article caption,
.content caption,
.typography h3,
article h3,
.content h3 {
  margin-top: 2em;
  font-size: 1.25em;
}
@media (min-width: 1280px) {
  .ty .h3,
.ty caption,
.ty h3,
.typography .h3,
article .h3,
.content .h3,
.typography caption,
article caption,
.content caption,
.typography h3,
article h3,
.content h3 {
    font-size: 1.5em;
  }
}
@media print {
  .ty .h3,
.ty caption,
.ty h3,
.typography .h3,
article .h3,
.content .h3,
.typography caption,
article caption,
.content caption,
.typography h3,
article h3,
.content h3 {
    font-size: 14pt;
  }
}
.ty .h4, .ty .boxes .box .inner > p a, .boxes .box .inner > p .ty a, .ty .boxes .box .inner h3, .boxes .box .inner .ty h3,
.ty h4,
.ty h5,
.typography .h4,
.typography .boxes .box .inner > p a,
.boxes .box .inner > p .typography a,
.typography .boxes .box .inner h3,
.boxes .box .inner .typography h3,
article .h4,
article .boxes .box .inner > p a,
.boxes .box .inner > p article a,
article .boxes .box .inner h3,
.boxes .box .inner article h3,
.content .h4,
.content .boxes .box .inner > p a,
.boxes .box .inner > p .content a,
.content .boxes .box .inner h3,
.boxes .box .inner .content h3,
.typography h4,
article h4,
.content h4,
.typography h5,
article h5,
.content h5 {
  font-size: 1.2em;
  font-weight: bold;
}
.ty h1 + *,
.ty h2 + *,
.ty h3 + *,
.ty .coltop + h1,
.ty .coltop + h2,
.ty .coltop + h3,
.ty .coltop + h4,
.ty .coltop + h5,
.ty a[name=copytop] + h1,
.ty a[name=copytop] + h2,
.ty a[name=copytop] + h3,
.ty a[name=copytop] + h4,
.ty a[name=copytop] + h5,
.typography h1 + *,
article h1 + *,
.content h1 + *,
.typography h2 + *,
article h2 + *,
.content h2 + *,
.typography h3 + *,
article h3 + *,
.content h3 + *,
.typography .coltop + h1,
article .coltop + h1,
.content .coltop + h1,
.typography .coltop + h2,
article .coltop + h2,
.content .coltop + h2,
.typography .coltop + h3,
article .coltop + h3,
.content .coltop + h3,
.typography .coltop + h4,
article .coltop + h4,
.content .coltop + h4,
.typography .coltop + h5,
article .coltop + h5,
.content .coltop + h5,
.typography a[name=copytop] + h1,
article a[name=copytop] + h1,
.content a[name=copytop] + h1,
.typography a[name=copytop] + h2,
article a[name=copytop] + h2,
.content a[name=copytop] + h2,
.typography a[name=copytop] + h3,
article a[name=copytop] + h3,
.content a[name=copytop] + h3,
.typography a[name=copytop] + h4,
article a[name=copytop] + h4,
.content a[name=copytop] + h4,
.typography a[name=copytop] + h5,
article a[name=copytop] + h5,
.content a[name=copytop] + h5 {
  margin-top: 0;
}
.ty a,
.typography a,
article a,
.content a {
  color: #8968a6;
}
.ty a:hover,
.typography a:hover,
article a:hover,
.content a:hover {
  color: #242424;
}
.ty a:hover:active,
.typography a:hover:active,
article a:hover:active,
.content a:hover:active {
  color: #8968a6;
}
.ty .wp-caption p,
.typography .wp-caption p,
article .wp-caption p,
.content .wp-caption p {
  font-size: 0.8em;
  margin: 0.5em 0 1em 0;
}

#mc_embed_signup .button,
a.button,
.button {
  -webkit-appearance: none;
  appearance: none;
  display: inline-block;
  padding: 0.5em 1em 0.7em;
  background: #9538DF;
  color: white !important;
  font-family: synthese, sans-serif;
  text-decoration: none;
  font-size: 1em;
  font-weight: bold;
  border: none;
  text-align: center;
}
#mc_embed_signup .button.block,
a.button.block,
.button.block {
  display: block;
  width: 100%;
}
#mc_embed_signup .button.small,
a.button.small,
.button.small {
  font-size: var(--fs-small);
}
#mc_embed_signup .button.black,
a.button.black,
.button.black {
  background: var(--c-copy);
}
#mc_embed_signup .button.black:hover,
a.button.black:hover,
.button.black:hover {
  background: var(--c-corporate);
}
#mc_embed_signup .button:hover,
a.button:hover,
.button:hover {
  color: white;
  background: #601998;
}
#mc_embed_signup .button:hover:active,
a.button:hover:active,
.button:hover:active {
  background: #601998;
}

.mb {
  margin-bottom: var(--m-vert);
}
.mb:not(:first-child) {
  margin-top: var(--m-vert);
}

.text + .mb {
  margin-top: 0;
}

div.taxonomy.swiper-container .swiper-slide {
  width: auto;
}
div.taxonomy.swiper-container .swiper-slide span {
  width: auto;
  display: flex;
  justify-content: center;
  margin: 0 0 0 var(--gap);
  padding: 0.3em 1em 0.5em;
  line-height: 1;
  border: solid 2px var(--c-copy);
  font-family: synthese, sans-serif;
  border-radius: var(--radius-small);
  list-style: none;
  cursor: pointer;
  white-space: nowrap;
}
div.taxonomy.swiper-container .swiper-slide span:first-child {
  margin-left: 0;
}
div.taxonomy.swiper-container .swiper-slide span:hover {
  background: var(--c-copy);
  color: white;
}

img.lazy {
  opacity: 0;
}

@font-face {
  font-family: "PT Serif";
  font-weight: normal;
  src: url(../fonts/PTSerif-Regular.ttf) format("truetype");
}
/* Font-Groessen */
/* Dimensionen */
html {
  zoom: 100%;
  box-sizing: border-box;
}
html * {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: "PT Serif", sans-serif;
  font-size: var(--fs-copy);
  -webkit-font-smoothing: antialiased;
  color: var(--c-copy);
  background: #f0eee7;
  scroll-behavior: smooth;
}
@media print {
  body {
    font-size: 11pt;
    background: white !important;
  }
}

.wrap {
  padding: 0 20px;
  margin: 0 auto;
  position: relative;
}
@media (min-width: 375px) {
  .wrap {
    padding: 0 30px;
  }
}
@media (min-width: 1024px) {
  .wrap {
    max-width: 75.78125vw;
    padding: 0;
  }
  .wrap.wide {
    max-width: 1000px;
  }
}
@media (min-width: 1280px) {
  .wrap {
    max-width: 1000px;
  }
  .wrap.section {
    max-width: 1186px;
  }
  .wrap.wide {
    max-width: 1186px;
  }
}

#wrapall {
  margin: 0 auto;
  max-width: 1440px;
  overflow: hidden;
  background: white;
}
@media print {
  #wrapall {
    box-shadow: none;
  }
}
@media (min-width: 1442px) {
  #wrapall {
    margin: var(--gap) auto;
    border-radius: var(--gap);
  }
}

@font-face {
  font-family: "PT Serif";
  font-weight: normal;
  src: url(../fonts/PTSerif-Regular.ttf) format("truetype");
}
/* Font-Groessen */
/* Dimensionen */
.question,
.tipp,
.infobox {
  box-sizing: border-box;
  padding: 0.8rem 1rem;
  margin: 0 0 1em 0;
  color: var(--c-copy);
}
.question ul:last-child,
.question ul:last-child li:last-child,
.question p:last-child,
.tipp ul:last-child,
.tipp ul:last-child li:last-child,
.tipp p:last-child,
.infobox ul:last-child,
.infobox ul:last-child li:last-child,
.infobox p:last-child {
  margin-bottom: 0;
}
.question .boxtop + h1,
.question .boxtop + h2,
.question .boxtop + h3,
.tipp .boxtop + h1,
.tipp .boxtop + h2,
.tipp .boxtop + h3,
.infobox .boxtop + h1,
.infobox .boxtop + h2,
.infobox .boxtop + h3 {
  margin-top: 0;
}
.question p:last-child,
.tipp p:last-child,
.infobox p:last-child {
  margin-bottom: 0 !important;
}
.question:last-child,
.tipp:last-child,
.infobox:last-child {
  margin-bottom: 0;
}
.question.bold,
.tipp.bold,
.infobox.bold {
  background: #8968a6;
  color: white;
}
.question.bold h1,
.question.bold h2,
.question.bold h3,
.question.bold h4,
.tipp.bold h1,
.tipp.bold h2,
.tipp.bold h3,
.tipp.bold h4,
.infobox.bold h1,
.infobox.bold h2,
.infobox.bold h3,
.infobox.bold h4 {
  color: white;
}
.question.bold a,
.tipp.bold a,
.infobox.bold a {
  color: white;
}
.question.bold a:hover,
.tipp.bold a:hover,
.infobox.bold a:hover {
  color: white;
}

.question {
  cursor: pointer;
}

.answer {
  display: none;
}

@font-face {
  font-family: "PT Serif";
  font-weight: normal;
  src: url(../fonts/PTSerif-Regular.ttf) format("truetype");
}
/* Font-Groessen */
/* Dimensionen */
@media screen and (min-width: 850px) {
  .jPanelMenu-panel {
    display: none;
  }
}

@keyframes kenburns {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
header.main-header {
  display: flex;
  position: relative;
  padding: var(--gap-2x) var(--gap);
  font-family: "PT Serif", sans-serif;
  font-weight: 300;
  align-items: flex-start;
  justify-items: flex-start;
  font-size: 1rem;
}
@media (min-width: 600px) {
  header.main-header {
    padding: var(--gap-2x) var(--gap-2x) var(--m-vert);
    width: 100%;
  }
}
@media print {
  header.main-header {
    padding: var(--gap-2x) var(--gap);
  }
}
header.main-header .logo {
  display: flex;
  align-items: center;
  text-decoration: none;
}
header.main-header .logo img {
  width: 3.24em;
}
header.main-header .logo > div {
  margin-left: 0.71em;
  color: var(--c-copy);
  text-decoration: none;
  line-height: 1.2;
  margin-right: var(--gap);
  font-size: 1.24rem;
}
@media (min-width: 1280px) {
  header.main-header .logo > div {
    font-size: 1.375em;
  }
}
header.main-header .logo > div p:first-child {
  font-family: synthese, sans-serif;
  font-weight: 700;
}
@media print {
  header.main-header .nav {
    display: none;
  }
}
header.main-header .nav .socials img {
  width: 2.4em;
}
header.main-header .nav .socials a + a {
  margin-left: var(--gap);
}
@media (min-width: 600px) {
  header.main-header .nav .socials {
    display: none;
  }
}
@media (max-width: 599px) {
  header.main-header .nav {
    position: fixed;
    z-index: 1986;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transform: translateX(0);
    transition: all 400ms;
  }
  header.main-header .nav nav {
    margin-bottom: var(--gap-2x);
  }
  header.main-header .nav.closed {
    opacity: 0;
    transition: all 400ms;
    transform: translateX(100%);
  }
}
header.main-header nav {
  font-family: synthese, sans-serif;
  font-size: 1.246rem;
}
@media (min-width: 600px) {
  header.main-header nav {
    font-size: 0.875rem;
  }
}
@media (min-width: 1280px) {
  header.main-header nav {
    font-size: 1rem;
  }
}
@media (min-width: 1440px) {
  header.main-header nav {
    font-size: 1em;
  }
}
header.main-header nav ul {
  display: flex;
}
@media (max-width: 599px) {
  header.main-header nav ul {
    flex-direction: column;
  }
  header.main-header nav ul li:not(:last-child) {
    margin-bottom: 1em;
  }
}
@media (min-width: 600px) {
  header.main-header nav ul.side {
    position: absolute;
    right: 0;
    top: 0;
    transform: rotate(-90deg);
    transform-origin: bottom right;
  }
  header.main-header nav ul li {
    margin-left: 2em;
  }
}
header.main-header nav ul li a {
  color: var(--c-copy);
  text-decoration: none;
}
header.main-header nav ul li a.active {
  color: var(--c-corporate);
}
header.main-header nav ul li a:hover {
  color: var(--c-corporate);
}
header.main-header nav ul.main {
  font-weight: 700;
  margin-top: 4px;
}
@media (min-width: 600px) {
  header.main-header nav.side {
    position: absolute;
    z-index: 2;
    top: calc(50px + var(--m-vert));
    left: calc(var(--gap-2x) + 1em);
  }
}
header.main-header .socials {
  display: flex;
  align-items: stretch;
  margin-left: auto;
}
@media print {
  header.main-header .socials {
    display: none;
  }
}
@media (max-width: 413px) {
  header.main-header .socials.head {
    display: none;
  }
}
header.main-header .socials.head img {
  width: 1.8em;
  margin-left: 1em;
}
header.main-header .socials span {
  display: flex;
  align-items: center;
  font-family: var(--ff-head);
  height: 100%;
  padding: 0 1em 0.2em;
  text-decoration: none;
  font-weight: bold;
  background: var(--c-copy);
  color: white;
  border-radius: 8px;
  text-transform: uppercase;
}
header.main-header .rwd {
  position: absolute;
  z-index: 1989;
  right: var(--gap);
  top: 50%;
  width: 30px;
  height: 18px;
  transform: translateY(-50%);
}
@media (min-width: 600px) {
  header.main-header .rwd {
    display: none;
  }
}
header.main-header .rwd span {
  position: absolute;
  left: 50%;
  width: 30px;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center center;
  height: 1px;
  background: var(--c-copy);
}
header.main-header .rwd span.r1 {
  top: 0;
}
header.main-header .rwd span.r3 {
  top: auto;
  bottom: 0;
}
header.main-header .rwd:not(.closed) .r2 {
  display: none;
}
header.main-header .rwd:not(.closed) .r1 {
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}
header.main-header .rwd:not(.closed) .r3 {
  bottom: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}

article > .content:not(:first-child) {
  padding: var(--m-vert) 0;
}

@media (max-width: 1023px) {
  .content ._brlbs-switch-consent, .content > .text, .content > .taxonomies, .content > .podcastfeeds, .content > .columns, .content > .newsletterform, .content > .abstract, .feature > .h1 p:first-child .content > strong, .ty .feature > h1 p:first-child .content > strong,
.typography .feature > h1 p:first-child .content > strong,
.content .feature > h1 p:first-child .content > strong,
article .feature > h1 p:first-child .content > strong,
.feature .text p:first-child .content > strong, .content > .feature, .content > .wrapThis, .content > form, .content > ul, .content > ol, .content > blockquote, .content > p, .content > dd, .content > h1, .content > h2, .content > h3, .content > h4, .content > h5, .content > h6, .content > .f-text, .content > .f-header, .content > .f-icons, .content > .f-spalten, .content > .f-flow, .content > .f-columns, .content > .f-columns_linked, .content > .f-testff, .content > .infobox, .content > .boxes, .content > figure {
    padding-left: var(--gap);
    padding-right: var(--gap);
  }
}
@media (min-width: 1024px) {
  .content ._brlbs-switch-consent, .content > .text, .content > .taxonomies, .content > .podcastfeeds, .content > .columns, .content > .newsletterform, .content > .abstract, .feature > .h1 p:first-child .content > strong, .ty .feature > h1 p:first-child .content > strong,
.typography .feature > h1 p:first-child .content > strong,
.content .feature > h1 p:first-child .content > strong,
article .feature > h1 p:first-child .content > strong,
.feature .text p:first-child .content > strong, .content > .feature, .content > .wrapThis, .content > form, .content > ul, .content > ol, .content > blockquote, .content > p, .content > dd, .content > h1, .content > h2, .content > h3, .content > h4, .content > h5, .content > h6, .content > .f-text, .content > .f-header, .content > .f-icons, .content > .f-spalten, .content > .f-flow, .content > .f-columns, .content > .f-columns_linked, .content > .f-testff, .content > .infobox, .content > .boxes, .content > figure {
    margin-left: var(--margin);
    margin-right: var(--margin);
  }
}
.content > form, .content > .podcastfeeds, .content > .newsletterform, .content > .abstract, .feature > .h1 p:first-child .content > strong, .ty .feature > h1 p:first-child .content > strong,
.typography .feature > h1 p:first-child .content > strong,
.content .feature > h1 p:first-child .content > strong,
article .feature > h1 p:first-child .content > strong,
.feature .text p:first-child .content > strong, .content > .text {
  padding-left: var(--gap);
  margin-bottom: var(--m-vert-slim);
}
@media (min-width: 1280px) {
  .content > form, .content > .podcastfeeds, .content > .newsletterform, .content > .abstract, .feature > .h1 p:first-child .content > strong, .ty .feature > h1 p:first-child .content > strong,
.typography .feature > h1 p:first-child .content > strong,
.content .feature > h1 p:first-child .content > strong,
article .feature > h1 p:first-child .content > strong,
.feature .text p:first-child .content > strong, .content > .text {
    padding-left: calc(var(--m-vert) + var(--gap-2x));
  }
}
.content > ul {
  margin-top: 2em;
  margin-bottom: 2em;
}
.content h2 + ul {
  margin-top: 1em;
}
.content p:first-child:empty {
  display: none;
}
.content > .group {
  margin-left: auto;
  margin-right: auto;
}
.content > .group {
  margin-bottom: 1em;
}
.content figure {
  margin: var(--m-vert-slim) 0;
}
.content figcaption {
  margin-top: 1em;
  font-size: var(--fs-small);
}
.content li {
  background: none;
  padding-left: 0;
}
.content .columns {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.content .video {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1), 0 10px 6px -6px rgba(0, 0, 0, 0.3);
  position: relative;
  width: 100%;
  padding-bottom: 56%;
  background: #efefef;
  margin-top: var(--m-vert-slim);
  margin-bottom: var(--m-vert-slim);
}
@media print {
  .content .video {
    display: none;
  }
}
.content .video iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.content .col-art {
  background: #c5403d;
}
.content .col-code {
  background: #52a049;
}
.content .col-mentoring {
  background: #4063c6;
}

.content > img:only-child {
  margin-top: calc(var(--gap-2x) - 1rem);
  margin-bottom: var(--gap-2x);
}

.taxonomies.swiper-container .taxonomy {
  width: auto;
  padding: 0.3em 1em;
  background: var(--c-copy);
  color: white;
}
.taxonomies.swiper-container .taxonomy.active {
  background-color: #8968a6;
}

#soulstrips {
  margin: var(--gap-2x);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--gap-2x);
}
@media (min-width: 600px) {
  #soulstrips {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1024px) {
  #soulstrips {
    grid-template-columns: repeat(5, 1fr);
  }
}
#soulstrips a {
  display: block;
}
#soulstrips a img {
  position: relative;
}
#soulstrips a div {
  background: rgba(0, 0, 0, 0.075);
  mix-blend-mode: multiply;
}

.podcastfeeds {
  display: grid;
  grid-gap: 1rem;
  margin: var(--m-vert-slim) 0;
}
@media (min-width: 600px) {
  .podcastfeeds {
    grid-template-columns: repeat(2, 1fr);
  }
}
.podcastfeeds a {
  display: flex;
  align-items: flex-start;
  color: white;
  padding: 1rem;
  border-radius: 1.5rem;
  text-decoration: none;
  background: #242424;
  color: white !important;
}
.podcastfeeds a:hover {
  transform: scale(1.024);
  color: white;
}
.podcastfeeds a span {
  margin-left: 1rem;
}
.podcastfeeds a h3 {
  margin: 0;
  font-size: 1.2rem;
}
.podcastfeeds a p {
  margin: 0;
  font-size: 0.8rem;
}

@font-face {
  font-family: "PT Serif";
  font-weight: normal;
  src: url(../fonts/PTSerif-Regular.ttf) format("truetype");
}
/* Font-Groessen */
/* Dimensionen */
article {
  clear: both;
  margin: 0 auto;
}
article figure {
  margin: 0;
}

.f-block {
  margin-bottom: var(--m-vert);
}
.f-block:last-child {
  margin-bottom: 0;
}

section.section header {
  display: flex;
  align-items: flex-end;
}
section.section header .h1, section.section header .ty h1, .ty section.section header h1,
section.section header .typography h1,
.typography section.section header h1,
section.section header .content h1,
.content section.section header h1,
section.section header article h1,
article section.section header h1 {
  margin-right: 0.71em;
  margin-bottom: 0;
}
section.section header p {
  font-family: synthese, sans-serif;
  font-weight: 700;
  font-size: 1.375em;
}

.green-audio-player {
  width: auto;
  margin-top: var(--m-vert-slim);
  margin-bottom: var(--m-vert-slim);
}
.green-audio-player .controls .controls__slider {
  margin-right: 0;
  width: auto;
}
.green-audio-player .pin.progress__pin {
  background-color: #8968a6 !important;
}

.banner {
  width: 100%;
}
.banner img {
  width: 100%;
}
.banner.top {
  width: calc(100% - var(--margin));
  margin-left: auto;
}

.f-header,
.f-icons {
  display: flex;
}

.f-icons-inner {
  display: grid;
  grid-gap: var(--gap);
}

.f-header > div {
  width: 50%;
  flex-shrink: 0;
}
.f-header > div + div {
  padding: 0 var(--gap);
}

.f-spalten,
.columns,
.f-columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--gap);
}

.boxes {
  display: grid;
  gap: var(--gap);
}
@media (min-width: 600px) {
  .boxes.boxes-1 {
    grid-template-columns:  1fr;
  }
  .boxes.boxes-2 {
    grid-template-columns:  1fr 1fr;
  }
  .boxes.boxes-3 {
    grid-template-columns:  1fr 1fr 1fr;
  }
  .boxes.boxes-4 {
    grid-template-columns:  1fr 1fr 1fr 1fr;
  }
  .boxes.boxes-5 {
    grid-template-columns:  1fr 1fr 1fr 1fr 1fr;
  }
  .boxes.boxes-6 {
    grid-template-columns:  1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .boxes.boxes-7 {
    grid-template-columns:  1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .boxes.boxes-8 {
    grid-template-columns:  1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .boxes.boxes-9 {
    grid-template-columns:  1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .boxes.boxes-10 {
    grid-template-columns:  1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .boxes.mod-2-0 {
    grsid-template-columns: 1fr 1fr;
  }
}
.boxes .box {
  background-color: var(--c-copy);
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
}
.boxes .box .inner {
  padding: var(--gap);
}
.boxes .box .inner > p {
  margin-top: var(--gap);
}
.boxes .box .inner > p a {
  font-family: synthese, sans-serif;
  text-decoration: none;
  color: white;
}
.boxes .box .inner > p a:hover {
  text-decoration: underline;
}
.boxes .box .image + .inner {
  padding-top: 0;
}

.offerings .wrap > div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: var(--gap);
}
.offerings .wrap > div a {
  text-decoration: none;
  padding: var(--gap);
  background: #c5403d;
  color: white;
}
.offerings .wrap > div a .h2,
.offerings .wrap > div a h2 {
  font-size: 1.5em;
}
.offerings .wrap > div h4 {
  margin-top: 3em;
}

.abstract, .feature > .h1 p:first-child strong, .ty .feature > h1 p:first-child strong,
.typography .feature > h1 p:first-child strong,
.content .feature > h1 p:first-child strong,
article .feature > h1 p:first-child strong,
.feature .text p:first-child strong {
  font-family: synthese, sans-serif;
  font-weight: 400;
  line-height: 1.4;
  font-size: 1.25em;
  margin-bottom: var(--m-vert-slim);
}
.abstract p, .feature > .h1 p:first-child strong p, .ty .feature > h1 p:first-child strong p,
.typography .feature > h1 p:first-child strong p,
.content .feature > h1 p:first-child strong p,
article .feature > h1 p:first-child strong p,
.feature .text p:first-child strong p {
  font-weight: 300;
}

.feature > div {
  display: grid;
  gap: var(--gap);
}
@media (min-width: 1024px) {
  .feature > div {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 1024px) {
  .feature > .h1, .ty .feature > h1,
.typography .feature > h1,
.content .feature > h1,
article .feature > h1,
.feature .text {
    padding-left: var(--gap);
  }
}
@media (min-width: 1280px) {
  .feature > .h1, .ty .feature > h1,
.typography .feature > h1,
.content .feature > h1,
article .feature > h1,
.feature .text {
    padding-left: var(--gap-2x);
  }
}
.imageportfolio {
  position: relative;
}
.imageportfolio .swiper-slide {
  width: auto;
}
.imageportfolio .swiper-slide img {
  height: 40vh;
  max-height: 480px;
  width: auto;
}
@media (min-width: 1280px) {
  .imageportfolio .swiper-slide img {
    height: 480px;
  }
}
.imageportfolio.websites .swiper-slide {
  max-width: 560px;
  max-height: auto;
}
.imageportfolio.websites .swiper-slide img {
  height: auto;
  width: 40vw;
}
@media (min-width: 1280px) {
  .imageportfolio.websites .swiper-slide {
    height: auto;
    width: 560px;
  }
}
.imageportfolio a {
  display: flex;
  transition: transform 100ms;
}
.imageportfolio a:hover imsg {
  transform: scale(1.01);
  transition: transform 0.8s;
}
.imageportfolio a:hover {
  transform: scale(1.024);
  transition: transform 200ms;
}
.imageportfolio .portfolio-nav span {
  cursor: pointer;
  position: absolute;
  z-index: 1986;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  background: white;
  border-radius: 19px;
}
.imageportfolio .portfolio-nav span.portfolio-next {
  left: auto;
  right: 8px;
  padding-left: 2px;
}
.imageportfolio .portfolio-nav span svg path {
  stroke: var(--c-copy);
}
.imageportfolio .portfolio-nav span:hover svg path {
  stroke: #9538DF;
}

.shoplink a {
  display: block;
  text-align: center;
  padding: var(--gap-2x) var(--gap);
  text-decoration: none;
  color: var(--c-copy);
}
.shoplink a span {
  display: block;
}
.shoplink a span:first-child {
  font-family: synthese, sans-serif;
  font-size: 1.375em;
  font-weight: 700;
  margin: 0 0 0.5em;
}
.shoplink a:hover span:first-child {
  color: #8968a6;
}

.box {
  margin: var(--m-vert-slim) auto;
  padding: var(--gap);
  background: #f0eee7;
}

.df-blocks-upload-wrap {
  position: relative;
}
.df-blocks-upload-wrap .df-blocks-upload-button {
  cursor: pointer;
  position: absolute;
  color: white;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 0.5em 1.5em;
  border: solid 1px white;
  border-radius: 8px;
}

.df-ui,
.df-upload {
  position: relative;
}
.df-ui .df-button,
.df-ui .df-upload-button,
.df-upload .df-button,
.df-upload .df-upload-button {
  font-family: sans-serif;
  line-height: 1;
  cursor: pointer;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 0.5em 1.5em 0.4em;
  border: solid 1px white !important;
  color: white !important;
  background-color: rgba(137, 104, 166, 0.8) !important;
  white-space: nowrap;
  font-size: 1rem;
  z-index: 2015;
  border-radius: 8px;
}
.df-ui .df-button.minus, .df-ui .df-button.plus,
.df-ui .df-upload-button.minus,
.df-ui .df-upload-button.plus,
.df-upload .df-button.minus,
.df-upload .df-button.plus,
.df-upload .df-upload-button.minus,
.df-upload .df-upload-button.plus {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  background-image: url('data:image/svg+xml;utf8,<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="1.74846e-07" y1="16" x2="32" y2="16" stroke="white" stroke-width="4"/> <line x1="16" y1="32" x2="16" stroke="white" stroke-width="4"/> </svg>');
  background-position: center;
  background-size: 28px;
  background-repeat: no-repeat;
}
.df-ui .df-button.minus.minus, .df-ui .df-button.plus.minus,
.df-ui .df-upload-button.minus.minus,
.df-ui .df-upload-button.plus.minus,
.df-upload .df-button.minus.minus,
.df-upload .df-button.plus.minus,
.df-upload .df-upload-button.minus.minus,
.df-upload .df-upload-button.plus.minus {
  background-image: url('data:image/svg+xml;utf8,<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="1.74846e-07" y1="16" x2="32" y2="16" stroke="white" stroke-width="4"/> </svg>');
}
.df-ui .df-button.small,
.df-ui .df-upload-button.small,
.df-upload .df-button.small,
.df-upload .df-upload-button.small {
  font-size: 0.86rem;
}
.df-ui .df-button:hover,
.df-ui .df-upload-button:hover,
.df-upload .df-button:hover,
.df-upload .df-upload-button:hover {
  background-color: #8968a6 !important;
}
.df-ui .df-button.top-left, .df-ui .df-button.topleft,
.df-ui .df-upload-button.top-left,
.df-ui .df-upload-button.topleft,
.df-upload .df-button.top-left,
.df-upload .df-button.topleft,
.df-upload .df-upload-button.top-left,
.df-upload .df-upload-button.topleft {
  left: 1em;
  top: 1em;
  transform: none;
}
.df-ui .df-button.top-right, .df-ui .df-button.topright,
.df-ui .df-upload-button.top-right,
.df-ui .df-upload-button.topright,
.df-upload .df-button.top-right,
.df-upload .df-button.topright,
.df-upload .df-upload-button.top-right,
.df-upload .df-upload-button.topright {
  left: auto;
  right: 1em;
  top: 1em;
  transform: none;
}
.df-ui .df-button.rightcenter, .df-ui .df-button.right-center,
.df-ui .df-upload-button.rightcenter,
.df-ui .df-upload-button.right-center,
.df-upload .df-button.rightcenter,
.df-upload .df-button.right-center,
.df-upload .df-upload-button.rightcenter,
.df-upload .df-upload-button.right-center {
  left: auto;
  right: 0;
  transform: translate(50%, -50%);
}
.df-ui .df-button.static,
.df-ui .df-upload-button.static,
.df-upload .df-button.static,
.df-upload .df-upload-button.static {
  position: static;
  transform: none;
  left: 0;
  top: 0;
  margin: 1em 0;
}

table {
  width: 100%;
  background: white;
  font-size: var(--fs-copy);
}

td, th {
  border-top: solid 1px #dddddd;
  border-left: solid 1px #dddddd;
  padding: 0.5em 0.7em;
}

th {
  background: #474747;
  color: white;
  font-family: synthese, sans-serif;
}

th:first-child,
td:first-child {
  border-left: none;
}

tbody tr:nth-child(odd) td {
  background-color: #eeeeee;
}

@font-face {
  font-family: "PT Serif";
  font-weight: normal;
  src: url(../fonts/PTSerif-Regular.ttf) format("truetype");
}
/* Font-Groessen */
/* Dimensionen */
form p {
  margin: 0;
}

.form-field {
  margin: 0 0 var(--gap) 0;
}

label {
  display: block;
  margin: var(--gap) 0 0.3em;
  font-size: 1em;
}

textarea,
select,
input[type=email],
input[type=search],
input[type=text] {
  font-family: "PT Serif", sans-serif;
  padding: 0.5em 1em 0.7em;
  background: #f0eee7;
  margin: 0;
  width: 100%;
  border: none !important;
  font-size: 1.68em !important;
  border-radius: 0 !important;
}

input[type=checkbox],
input[type=radio],
input[type=submit],
input[type=button],
select {
  cursor: pointer;
}

#mc_embed_signup form {
  margin: 0 !important;
}

@font-face {
  font-family: "PT Serif";
  font-weight: normal;
  src: url(../fonts/PTSerif-Regular.ttf) format("truetype");
}
/* Font-Groessen */
/* Dimensionen */
/************************************************************************************************/
/*
/* Formate
*/
article.format-video iframe {
  max-width: 100%;
}

.credits {
  color: var(--c-copy);
}
.credits p {
  font-size: 1em;
  margin: 0;
  float: left;
  margin: 0 20px 0 0;
}
.credits a {
  text-decoration: none;
}
.credits ul {
  font-size: 1em;
  float: left;
  margin: 0 20px 0 0;
}
.credits ul li {
  float: left;
  background: none;
  padding: 0;
  margin: 0;
}
.credits ul li:after {
  content: ", ";
}
.credits ul li:first-child, .credits ul li:last-child {
  content: "";
}
.credits ul li:first-child:after, .credits ul li:last-child:after {
  content: "";
}

.frontpage .wrap-front {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding-left: var(--gap);
}
@media (min-width: 600px) {
  .frontpage .wrap-front {
    padding-left: var(--gap-2x);
  }
}
@media (min-width: 1024px) {
  .frontpage .wrap-front {
    padding-left: 200px;
  }
}
.frontpage .wrap-front .markus {
  display: flex;
  position: relative;
  z-index: 1;
  flex-shrink: 0;
  width: 50%;
}
.frontpage .wrap-front .rainbow {
  position: absolute;
  right: 0;
  bottom: 0;
  width: calc(50% - 22vw);
  height: 106.6666666667vw;
  background: linear-gradient(180deg, #da4b4b 0%, #e9ef56 20%, #56bf4a 40%, #49c7bf 60%, #5350c7 80%, #d65fc3 100%);
}
@media (min-width: 600px) {
  .frontpage .wrap-front .rainbow {
    width: calc(50% - var(--gap-2x));
  }
}
@media (min-width: 600px) {
  .frontpage .wrap-front .rainbow {
    height: 539px;
    width: calc(50% - var(--gap-4x));
  }
}
@media (min-width: 1024px) {
  .frontpage .wrap-front .rainbow {
    width: calc(50% - 200px);
  }
}
.frontpage .wrap-front .copy {
  flex-grow: 1;
}
@media (min-width: 600px) {
  .frontpage .wrap-front .copy {
    font-size: 1.375rem;
  }
}
@media (min-width: 1024px) {
  .frontpage .wrap-front .copy {
    font-size: 1em;
  }
}
@media (min-width: 1440px) {
  .frontpage .wrap-front .copy {
    font-size: 1.375rem;
  }
}
.frontpage .wrap-front .copy > p:first-child {
  font-size: 3em;
  font-family: synthese, sans-serif;
  font-weight: 700;
  margin: 0;
}
@media (min-width: 1024px) {
  .frontpage .wrap-front .copy > p:first-child {
    font-size: 3.875em;
  }
}
.frontpage .wrap-front .copy > p:first-child + p {
  margin: 0 0 1em;
  font-weight: 300;
}
.frontpage .wrap-front .copy h4 {
  font-size: 1.375em;
}
.frontpage + section {
  position: relative;
  z-index: 2;
  background: #50435B;
}

.more-blog-posts {
  margin-top: var(--m-vert);
}
@media print {
  .more-blog-posts {
    display: none;
  }
}
.more-blog-posts h2 {
  padding-bottom: var(--gap-2x);
}

.blog-and-pictures {
  position: relative;
  z-index: 2;
  background: #50435B;
}

article.blog .head {
  width: 100%;
  padding-right: var(--margin-wide);
}
@media (screen) {
  article.blog .head {
    padding-left: var(--margin-wide);
  }
}
article.blog .inner .date {
  margin-bottom: 1em;
}
@media (min-width: 1024px) {
  article.blog .inner {
    display: flex;
  }
  article.blog .inner .date {
    flex-shrink: 0;
    width: var(--margin);
    margin: 0;
  }
  article.blog .inner .text {
    margin-left: var(--gap);
  }
}

.pagination {
  display: flex;
  justify-content: center;
  font-family: var(--ff-head);
  margin-top: var(--m-vert-slim);
  margin-bottom: var(--m-vert-slim);
}
.pagination span,
.pagination a {
  display: block;
  color: var(--ff-copy);
  padding: 1em;
  text-decoration: none;
  font-size: var(--fs-large);
}
.pagination span {
  opacity: 0.24;
}

.grid {
  opacity: 0;
  max-width: var(--max-width);
  margin: 0 var(--gap);
}
.grid.show {
  opacity: 1;
}

.grid-sizer-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
}
@media (min-width: 1204px) {
  .grid-sizer-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1280px) {
  .grid-sizer-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.grid-sizer:not(.ignore) {
  display: block;
  width: 100%;
}
@media (min-width: 60px) {
  .grid-sizer:not(.ignore) {
    width: calc(50% - var(--gap) / 2);
  }
}
@media (min-width: 1024px) {
  .grid-sizer:not(.ignore) {
    --gap2: calc(2 * var(--gap));
    width: calc(33.333% - var(--gap2) / 3);
  }
}
@media (min-width: 1280px) {
  .grid-sizer:not(.ignore) {
    --gap3: calc(3 * var(--gap));
    width: calc(25% - var(--gap3) / 4);
  }
}

.grid-gutter {
  display: block;
  width: var(--gap);
}

.grid {
  padding: var(--gap) 0;
}
@media (min-width: 1024px) {
  .grid:not(.no-offset) {
    transform: translateY(calc(-3 * var(--gap)));
  }
}

.grid-item {
  text-decoration: none;
  margin-bottom: 20px;
  width: 100%;
  overflow: hidden;
}
.grid-item:not(.picture) {
  border-radius: 4px;
}
.grid-item.picture {
  transform: scale(0.94);
}
.grid-item img {
  display: flex;
}
.grid-item div:first-child {
  display: flex;
}
.grid-item div:first-child img {
  display: flex;
  width: 100%;
}
.grid-item .hover {
  opacity: 0;
}
.grid-item .inner {
  border-radius: 4px;
}
@media (min-width: 600px) {
  .grid-item .inner {
    margin: 1em;
  }
}
.grid-item .inner,
.grid-item .bottom {
  padding: 1em;
  background: #8968a6;
  color: white;
}
@media (min-width: 600px) {
  .grid-item .inner,
.grid-item .bottom {
    padding: var(--gap);
  }
}
.grid-item .inner h3,
.grid-item .bottom h3 {
  font-size: 12px;
  font-weight: 700;
  text-transform: none;
  margin: 0;
}
@media (min-width: 600px) {
  .grid-item .inner h3,
.grid-item .bottom h3 {
    font-size: 1em;
  }
}
.grid-item .inner > p,
.grid-item .bottom > p {
  margin: 1em 0;
  font-size: 0.75em;
}
.grid-item .inner > p:last-child,
.grid-item .bottom > p:last-child {
  margin-bottom: 0;
  opacity: 0.5;
}
@media (max-width: 599px) {
  .grid-item .inner > p:last-child,
.grid-item .bottom > p:last-child {
    display: none;
  }
}
.grid-item .inner .excerpt,
.grid-item .bottom .excerpt {
  font-size: 0.75em;
  background: rgba(0, 0, 0, 0.86);
  padding: var(--gap);
  display: none;
}
@media (min-width: 1024px) {
  .grid-item:hover .hover {
    opacity: 1;
  }
  .grid-item:hover .bottom .excerpt {
    display: flex;
  }
}

article.book .head {
  margin-bottom: var(--gap-2x);
}
@media print {
  article.book .head .shelf {
    display: none;
  }
}
@media (min-width: 1024px) {
  article.book .head {
    padding-left: calc(24% + var(--gap-2x));
  }
  article.book .head p {
    flex-shrink: 0;
    display: flex;
    align-items: center;
  }
}
article.book .head p a {
  font-family: var(--ff-head);
  opacity: 0.5;
  color: var(--c-copy);
}
article.book h1 {
  margin: 0 !important;
}
article.book h1 span:first-child {
  font-size: 0.5em;
  font-weight: normal;
}
@media (max-width: 767px) {
  article.book .image {
    display: flex;
    justify-content: center;
  }
  article.book .image span {
    display: block;
  }
  article.book .image img {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  article.book .image img {
    max-width: 240px;
  }
}
article.book .inner .details {
  margin-bottom: 1em;
  font-size: 16px;
  text-align: center;
}
article.book .inner .details p {
  font-family: var(--ff-head);
  margin: 0 0 0.5em;
}
article.book .inner .details .button {
  background-color: var(--c-copy);
  display: block;
}
@media print {
  article.book .inner .details .buy_on {
    display: none;
  }
}
article.book .inner .details .buy_on:nth-of-type(1) {
  margin-top: var(--gap);
}
@media (min-width: 1024px), print {
  article.book .inner {
    display: flex;
  }
  article.book .inner .details {
    flex-shrink: 0;
    width: 24%;
    margin: 0;
  }
  article.book .inner .text-wrap {
    margin-left: var(--gap-2x);
  }
}
article.book .read_too {
  margin: var(--gap-2x) 0;
}

.bookshelf {
  width: 100%;
  display: grid;
  grid-gap: var(--gap-2x);
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--gap);
}
@media (min-width: 768px) {
  .bookshelf {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1024px) {
  .bookshelf {
    grid-template-columns: repeat(4, 1fr);
  }
}
.bookshelf .book {
  display: flex;
  flex-direction: column;
}
.bookshelf .book img {
  width: 100%;
}
.bookshelf .book .image {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 140%;
  position: relative;
}
.bookshelf .book .image a {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 767px) {
  .bookshelf .book .image a {
    max-width: 246px;
  }
}
.bookshelf .book .image img {
  width: 86%;
}
.bookshelf .book .image a {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.bookshelf .book .image a img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  height: 100%;
  object-fit: contain;
  object-position: center;
  transition: transform 0.3s;
}
.bookshelf .book .image a span {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 1em;
  right: -0.5em;
  z-index: 1986;
  width: 4.5em;
  border-radius: 4em;
  padding: 1em;
  background: var(--c-copy);
  color: white;
  border: solid 3px white;
  transform: scale(1) rotate(-6deg);
  transition: transform 0.5s;
}
.bookshelf .book .image a:hover img {
  transform: translateX(-50%) scale(1.05);
}
.bookshelf .book .image a:hover span {
  background: var(--c-corporate);
  transform: scale(1.1) rotate(6deg);
}
.bookshelf .book .details {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: var(--gap);
  font-family: var(--ff-head);
}
.bookshelf .book .details .title {
  min-height: 5.5em;
}
@media (min-width: 768px) {
  .bookshelf .book .details .title {
    min-height: 5em;
  }
}
.bookshelf .book .details .title h3, .bookshelf .book .details .title p {
  margin: 0;
}
.bookshelf .book .details .title h3 {
  font-size: var(--fs-copy);
  font-weight: bold;
}
.bookshelf .book .details .title p {
  font-size: var(--fs-small);
}
.bookshelf .book .details .buy_on {
  text-align: center;
  margin: 1em 0;
}
.bookshelf .book .details .buy_on.two-buttons {
  display: grid;
  grid-gap: 2px;
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 767px) {
  .bookshelf .book .details .buy_on .lang {
    display: none;
  }
}
.bookshelf .book .details .more {
  font-size: var(--fs-small);
  text-decoration: none;
  color: var(--c-copy);
}

article.blog:has(.text > .outer > .bookshelf) .outer {
  background: var(--c-fond);
  padding: var(--gap);
  margin-top: var(--gap-2x);
  margin-bottom: var(--gap-2x);
}
article.blog:has(.text > .outer > .bookshelf) .bookshelf {
  gap: 1em;
}
@media (min-width: 1024px) {
  article.blog:has(.text > .outer > .bookshelf) .bookshelf {
    grid-template-columns: repeat(3, 1fr);
  }
  article.blog:has(.text > .outer > .bookshelf) .bookshelf h3,
article.blog:has(.text > .outer > .bookshelf) .bookshelf .buy_on {
    font-size: var(--fs-small);
  }
}

img.bookcover {
  margin: 0;
  width: calc(100% - 6px);
  box-shadow: 1px 1px 0 white, 2px 2px 0 #868686, 3px 3px 0 white, 4px 4px 0 #868686, 5px 5px 0 white, 6px 6px 0 #868686;
}

@media (min-width: 550px) {
  .f-left,
.alignleft {
    float: left;
  }
}

@media (min-width: 550px) {
  .f-right,
.alignright {
    float: right;
  }
}

.aligncenter,
.alignright,
.alignleft {
  display: block;
  margin: 0 auto;
}
@media (min-width: 550px) {
  .aligncenter,
.alignright,
.alignleft {
    display: inline;
    margin: 0 10px 10px 0;
  }
}

@media (min-width: 550px) {
  .alignright {
    display: inline;
    margin: 0 0 10px 10px;
  }
}

.a-left,
.text-left {
  text-align: left;
}

.a-right,
.text-right {
  text-align: right;
}

@font-face {
  font-family: "PT Serif";
  font-weight: normal;
  src: url(../fonts/PTSerif-Regular.ttf) format("truetype");
}
/* Font-Groessen */
/* Dimensionen */
footer {
  padding: 2em;
  border: solid var(--gap) white;
  background: #f0eee7;
  display: flex;
  justify-content: center;
}
@media print {
  footer {
    display: none;
  }
}
footer .wrap {
  max-width: 720px;
}
footer a {
  color: #087da8 !important;
  text-decoration: none;
}
footer .ty h3 {
  margin: 1em 0;
}
footer .alternative {
  display: flex;
  justify-content: center;
}
footer .alternative a {
  color: var(--c-copy) !important;
  font-size: var(--fs-small);
}
@media (min-width: 600px) {
  footer .alternative a {
    margin: 0 1em;
  }
}
footer .alternative a span {
  display: block;
}
footer nav ul {
  display: flex;
  justify-content: center;
}
footer nav ul li {
  margin: 0 1em;
}

section.legal {
  background: white;
  display: flex;
  justify-content: center;
  padding: 0 var(--gap) var(--gap);
  font-size: var(--fs-small);
}
@media print {
  section.legal {
    display: none;
  }
}
section.legal a {
  color: var(--c-copy);
  text-decoration: none;
}

.london {
  padding: 1em 0;
  text-align: center;
  background: var(--c-copy);
  color: white;
  font-size: 1.55em;
  line-height: 1;
  font-family: synthese, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  background: linear-gradient(90deg, red 0%, #ff9a00 10%, #d0de21 20%, #4fdc4a 30%, #3fdad8 40%, #2fc9e2 50%, #1c7fee 60%, #5f15f2 70%, #ba0cf8 80%, #fb07d9 90%, red 100%) 0 0/200% 100%;
  animation: a 24s linear infinite;
}
@media print {
  .london {
    display: none;
  }
}

@keyframes a {
  to {
    background-position: -200% 0;
  }
}
.pr {
  position: relative;
}
.pr.pa1 {
  width: 100%;
  padding-bottom: 100%;
}
.pr.pa1.wide {
  padding-bottom: 56%;
}

.pa {
  position: absolute;
}
.pa.l0 {
  left: 0;
}
.pa.t0 {
  top: 0;
}
.pa.r0 {
  right: 0;
}
.pa.b0 {
  bottom: 0;
}

.pa1 {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.pa1.of {
  object-fit: cover;
}

.fxc {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fxsb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fxsb.afs {
  align-items: flex-start;
}
.fxsb.afe {
  align-items: flex-end;
}

.txc {
  text-align: center;
}

.hidemove,
.hideMove {
  position: fixed;
  top: -5000px;
}

.clear {
  clear: both;
}

.printonly {
  display: none;
}

.wp-caption,
img {
  max-width: 100%;
  height: auto;
}

.block {
  display: block;
  width: 100%;
}

.txc,
.text-center {
  text-align: center;
}

.blur-50 {
  -webkit-filter: url("#sharpBlur");
  filter: url("#sharpBlur");
}

.hideSvgSoThatItSupportsFirefox {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}