@import "config.scss";
form p {
    margin: 0;
}

.form-field {
    margin: 0 0 var(--gap) 0;
}
label {
    display: block;
    margin: var(--gap) 0 0.3em;
    font-size: 1em;
}

textarea,
select,
input[type="email"],
input[type="search"],
input[type="text"] {
    font-family: $ff_copy;
    padding: 0.5em 1em 0.7em;
    background: $c_fond;
    margin: 0;
    width: 100%;
    border: none !important;
    font-size: 1.68em !important;
    border-radius: 0 !important;
}

input[type="checkbox"],
input[type="radio"],
input[type="submit"],
input[type="button"],
select {
    cursor: pointer;
}

#mc_embed_signup form {
    margin: 0 !important;
}