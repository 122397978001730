.shoplink {
a {
    display: block;
    text-align: center;
    padding: var(--gap-2x) var(--gap);
    text-decoration: none;
    color: var(--c-copy);

    span {
        display: block;

        &:first-child {
            font-family: $ff_head;
            font-size: pxtoem(22);
            font-weight: 700;
            margin: 0 0 0.5em;
        }
    }

    &:hover {
        span:first-child {
            color: $c_corporate;
        }
    }
}
}