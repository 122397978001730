
.banner {
    width: 100%;
    img {
        width: 100%;
    }
    &.top {
        width: calc(100% - var(--margin));
        margin-left: auto;
    }
}
